//jQuery modules
//import $ from "../../../../../../node_modules/jquery";
import $ from "jquery";
import {infiniteSlide} from "./jquery/infiniteslide.js";
import {jqueryPause} from "./jquery/jquery.pause.js";
import {fixedHeader, slider} from "./jquery/main.js";
import {drawer} from "./jquery/drawer.js";

//general methods
import {fadeIn, fadeOut} from "./modules/_general_methods.js";

//npm libraries
import Cookies from 'js-cookie';

//Original Method
import {SugoudeMethods} from "./modules/_sugoudemethods.js";


//mainSlider
import { mainSwiperSliderCreate } from "./modules/_swiperSlider.js";
import { insertJapYear } from "./pages/_archive-pages.js";

//_top-page.js
import { removeTrashesOnTopMedia } from "./pages/_top-page.js";




(()=>{
  
  //FixedHeader
  fixedHeader();
  //SwiperOnMainImage
  mainSwiperSliderCreate();
  //DrawerMenu
  drawer();
  
  //アーカイブ年を追加する
  insertJapYear();

  //TOPページでパンくずリスト, フッターのInfo削除
  removeTrashesOnTopMedia();

  //CONTACTページでinputをリセット
  
})();
