/***********************************************/
/* Class SugoudeMethods Ver1.0                 */
/* Use with template index.html                */
/***********************************************/

export class SugoudeMethods{
  constructor(){
    // do something
  }

  //ロールオーバー
  MM_preloadImages() { //v3.0
    var d=document; if(d.images){ if(!d.MM_p) d.MM_p=new Array();
      var i,j=d.MM_p.length,a=MM_preloadImages.arguments; for(i=0; i<a.length; i++)
      if (a[i].indexOf("#")!=0){ d.MM_p[j]=new Image; d.MM_p[j++].src=a[i];}}
  }
  
  MM_swapImgRestore() { //v3.0
    var i,x,a=document.MM_sr; for(i=0;a&&i<a.length&&(x=a[i])&&x.oSrc;i++) x.src=x.oSrc;
  }
  
  MM_findObj(n, d) { //v4.01
    var p,i,x;  if(!d) d=document; if((p=n.indexOf("?"))>0&&parent.frames.length) {
      d=parent.frames[n.substring(p+1)].document; n=n.substring(0,p);}
    if(!(x=d[n])&&d.all) x=d.all[n]; for (i=0;!x&&i<d.forms.length;i++) x=d.forms[i][n];
    for(i=0;!x&&d.layers&&i<d.layers.length;i++) x=MM_findObj(n,d.layers[i].document);
    if(!x && d.getElementById) x=d.getElementById(n); return x;
  }
  
  MM_swapImage() { //v3.0
    var i,j=0,x,a=MM_swapImage.arguments; document.MM_sr=new Array; for(i=0;i<(a.length-2);i+=3)
     if ((x=MM_findObj(a[i]))!=null){document.MM_sr[j++]=x; if(!x.oSrc) x.oSrc=x.src; x.src=a[i+2];}
  }
  
  //ポップアップ--（フォーカス機能追加）
  MM_openBrWindow(theURL,winName,features) { //v2.0
  var winFocus;
  winFocus=window.open(theURL,winName,features);
  winFocus.focus();
  }
  
  //ページトップに戻る
  //指定方法（onclick="jumpToPageTop(event);return false;"）
  
  jumpToPageTop(e) {
    UAGENT = navigator.userAgent.toUpperCase();
    if (UAGENT.indexOf("MSIE") >=0) {
      pos = event.y;
    } else {
      pos = e.pageY;
    }
    pageScroll(pos);
  
  }
  
  pageScroll(posi) {
    dist = posi / 6;
    nPos = parseInt(posi - dist);
    scrollTo(0,nPos);
    if (nPos > 0) {
      setTimeout("pageScroll(nPos)",10);
    }
  }
  
  CloseWin(){
      window.close();
  }
  
  
  //レイヤーオープン
  r(q){
    document.getElementById("s").innerHTML="<div id=\"img\" style=\"border:1px #000000 solid;\"><object classid=\"clsid:D27CDB6E-AE6D-11cf-96B8-444553540000\" codebase=\"http:\/\/download.macromedia.com\/pub\/shockwave\/cabs\/flash\/swflash.cab#version=6,0,29,0\" width=\"700\" height=\"560\"><param name=\"movie\" value="+q+"><param name=\"quality\" value=\"high\"><embed src="+q+" quality=\"high\" pluginspage=\"http:\/\/www.macromedia.com\/go\/getflashplayer\" type=\"application\/x-shockwave-flash\" width=\"700\" height=\"560\"><\/embed><\/object><\/div>";
  }
  //閉じる
  c(){
    if(document.getElementById("s").innerHTML){
        if((navigator.userAgent.indexOf("Mac")!=-1)&&(navigator.userAgent.indexOf("MSIE")!=-1)){
          location.reload();
          }else{
            document.getElementById("s").innerHTML="";
            }
        }
  }
  
  
  
  
  //ロールオーバー
  //指定方法（DWのビヘイビアで設定）
  
  MM_preloadImages() { //v3.0
    var d=document; if(d.images){ if(!d.MM_p) d.MM_p=new Array();
      var i,j=d.MM_p.length,a=MM_preloadImages.arguments; for(i=0; i<a.length; i++)
      if (a[i].indexOf("#")!=0){ d.MM_p[j]=new Image; d.MM_p[j++].src=a[i];}}
  }
  
  MM_swapImgRestore() { //v3.0
    var i,x,a=document.MM_sr; for(i=0;a&&i<a.length&&(x=a[i])&&x.oSrc;i++) x.src=x.oSrc;
  }
  
  MM_findObj(n, d) { //v4.01
    var p,i,x;  if(!d) d=document; if((p=n.indexOf("?"))>0&&parent.frames.length) {
      d=parent.frames[n.substring(p+1)].document; n=n.substring(0,p);}
    if(!(x=d[n])&&d.all) x=d.all[n]; for (i=0;!x&&i<d.forms.length;i++) x=d.forms[i][n];
    for(i=0;!x&&d.layers&&i<d.layers.length;i++) x=MM_findObj(n,d.layers[i].document);
    if(!x && d.getElementById) x=d.getElementById(n); return x;
  }
  
  MM_swapImage() { //v3.0
    var i,j=0,x,a=MM_swapImage.arguments; document.MM_sr=new Array; for(i=0;i<(a.length-2);i+=3)
     if ((x=MM_findObj(a[i]))!=null){document.MM_sr[j++]=x; if(!x.oSrc) x.oSrc=x.src; x.src=a[i+2];}
  }
  
  //ポップアップ--（フォーカス機能追加）
  //指定方法（DWのビヘイビアで設定）
  MM_openBrWindow(theURL,winName,features) { //v2.0
  var winFocus;
  winFocus=window.open(theURL,winName,features);
  winFocus.focus();
  }
  
  //ページをプリントする
  //指定方法（javascript:printPage();）
  printPage() { print(document); }
  
  //お気に入りに追加する
  //指定方法（javascript:AddLikePage();）
  AddLikePage(){
    window.external.AddFavorite("http://www.xxxx.co.jp", "サイト名");
  }
  
  //ページトップに戻る
  //指定方法（javascript:jumpToPageTop();）
  getScrollLeft() { // 020225
   if ((navigator.appName.indexOf("Microsoft Internet Explorer",0) != -1)) {
    return document.body.scrollLeft;
   } else if (window.pageXOffset) {
    return window.pageXOffset;
   } else {
    return 0;
   }
  }
  
  getScrollTop() { // 020225
   if ((navigator.appName.indexOf("Microsoft Internet Explorer",0) != -1)) {
    return document.body.scrollTop;
   } else if (window.pageYOffset) {
    return window.pageYOffset;
   } else {
    return 0;
   }
  }
  
  getScrollWidth() { // 010317
   if ((navigator.appName.indexOf("Microsoft Internet Explorer",0) != -1)) {
    return document.body.scrollWidth;
   } else if (window.innerWidth) {
    return window.innerWidth;
   }
   return 0;
  }
  
  getScrollHeight() { // 010317
   if ((navigator.appName.indexOf("Microsoft Internet Explorer",0) != -1)) {
    return document.body.scrollHeight;
   } else if (window.innerHeight) {
    return window.innerHeight;
   }
   return 0;
  }
  
  pageScroll(toX,toY,frms,cuX,cuY) { // 020314
    let pageScrollTimer;
   if (pageScrollTimer) clearTimeout(pageScrollTimer);
   if (!toX || toX < 0) toX = 0;
   if (!toY || toY < 0) toY = 0;
   if (!cuX) cuX = 0 + getScrollLeft();
   if (!cuY) cuY = 0 + getScrollTop();
   if (!frms) frms = 6;
  
   if (toY > cuY && toY > (getAnchorPosObj('end','enddiv').y) - getInnerSize().height) toY = (getAnchorPosObj('end','enddiv').y - getInnerSize().height) + 1;
   cuX += (toX - getScrollLeft()) / frms; if (cuX < 0) cuX = 0;
   cuY += (toY - getScrollTop()) / frms;  if (cuY < 0) cuY = 0;
   var posX = Math.floor(cuX);
   var posY = Math.floor(cuY);
   window.scrollTo(posX, posY);
  
   if (posX != toX || posY != toY) {
    pageScrollTimer = setTimeout("pageScroll("+toX+","+toY+","+frms+","+cuX+","+cuY+")",16);
   }
  }
  
  jumpToPageTop() { // 020301
    pageScroll(0,0,5);
  }
  
  //ドロップダウンメニュー
  //id="navi+n"にclass="Show" class="Hidden"を追記する
  mn(n){
    let timerID="";
    if(timerID!=""){
      clearTimeout(timerID);
    }
    let val = n;
    dlt2();
    document.getElementById(val).className='Show';
  }
  dlt(){
    timerID=setTimeout("dlt2()",250);
  }
  dlt2(){
    for (i=1;i<4;i++){
      let val2 = "navi" + i;
      document.getElementById(val2).className='Hidden';
    }
  }
  
  maxNum(){ return 4; }
  
  $(id){
    return document.getElementById(id);
  }
  
  collect(list, lambda){
    var newList = new Array();
    for(var i = 0; i < list.length; i++){
      var e = list[i];
      newList.push(lambda(e));
    }
    return newList;
  }
  
  filter(list, p){
    var newList = new Array();
    for(var i = 0; i < list.length; i++){
      var e = list[i];
      if(p(e))
        newList.push(e);
    }
    return newList;
  }
  
  checkAndSubmit(form){
    var checkBoxes = filter(form.elements,
                            function(e){ return (e.type == 'checkbox' && e.checked) ? true : false; });
    var n = checkBoxes.length;
    if(n > 1 && n <= maxNum()){
      var pid = collect(checkBoxes, function(e){ return e.value; });
      var category = $('category').value;
      var q = 'prd=' + pid.join(',') + '&category=' + category;
      var url = form.action + '?' + q;
      window.open(url);
    }else if(n <= 1){
      alert('2個以上の項目を選択してください。');
    }else{
      alert('選択項目は' + maxNum() + '個までです。');
    }
  }
  
  
  
  //セレクトオープン〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓
  op(url)
  {
    window.open(url,"a","scrollbars=yes,width=780,height=650,top=0,left=0");
  }
  
  //セレクトロールオーバー〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓
  
  MM_swapImgRestore() { //v3.0
    var i,x,a=document.MM_sr; for(i=0;a&&i<a.length&&(x=a[i])&&x.oSrc;i++) x.src=x.oSrc;
  }
  
  MM_preloadImages() { //v3.0
    var d=document; if(d.images){ if(!d.MM_p) d.MM_p=new Array();
      var i,j=d.MM_p.length,a=MM_preloadImages.arguments; for(i=0; i<a.length; i++)
      if (a[i].indexOf("#")!=0){ d.MM_p[j]=new Image; d.MM_p[j++].src=a[i];}}
  }
  
  MM_findObj(n, d) { //v4.01
    var p,i,x;  if(!d) d=document; if((p=n.indexOf("?"))>0&&parent.frames.length) {
      d=parent.frames[n.substring(p+1)].document; n=n.substring(0,p);}
    if(!(x=d[n])&&d.all) x=d.all[n]; for (i=0;!x&&i<d.forms.length;i++) x=d.forms[i][n];
    for(i=0;!x&&d.layers&&i<d.layers.length;i++) x=MM_findObj(n,d.layers[i].document);
    if(!x && d.getElementById) x=d.getElementById(n); return x;
  }
  
  MM_swapImage() { //v3.0
    var i,j=0,x,a=MM_swapImage.arguments; document.MM_sr=new Array; for(i=0;i<(a.length-2);i+=3)
     if ((x=MM_findObj(a[i]))!=null){document.MM_sr[j++]=x; if(!x.oSrc) x.oSrc=x.src; x.src=a[i+2];}
  }

}
